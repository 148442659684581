<mat-card class="static-page">

<h1 class="heading"> Our Product</h1>
<div fxLayout="row" fxLayoutAlign="space-evenly center" f>   
    <mat-card class="card">
        <img  mat-card-image src="/assets/banner/net.jpeg" style="width:350px; height:200px;">
        <mat-card-content>
             <p>Networking</p>
        </mat-card-content>
    </mat-card>     

    <mat-card class="card">
        <img mat-card-image src="/assets/banner/server.jpeg" style="width:350px; height:200px;">
        <mat-card-content>      
            <p>Server</p>
        </mat-card-content>
    </mat-card>  

    <mat-card class="card">
        <img mat-card-image src="/assets/banner/tower.jpeg" style="width:350px; height:200px;">
        <mat-card-content>
             <p>Tower</p>
        </mat-card-content>
    </mat-card>     

    <mat-card class="card">
        <img mat-card-image src="/assets/banner/hardware.jpeg" style="width:350px; height:200px;">
        <mat-card-content>
             <p>Hardware Equipment</p>
        </mat-card-content>
    </mat-card>     



</div>

</mat-card>
