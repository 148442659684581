<div class="card">
<mat-card>

<ul>
   <li> Project Id : {{link_data.project_id}}</li>
   <li> Link Id : {{link_data.link_id}}</li>
   <li> Vendor Id : {{link_data.vendor_id}}</li>
   <li> Link Start Point  : {{link_data.link_start_point}}</li>
   <li> Link End Point : {{link_data.link_end_point}}</li>
   <li> Link Description : {{link_data.link_desctiprion}}</li>
   <li> Loink Start Date: {{link_data.Link_start_date}}</li>
   <li> Link Completion Date : {{link_data.Link_complete_date}}</li>
   <li> Link Vendor Length : {{link_data.link_vendor_length}}</li>
   <li> Link Official Length : {{link_data.link_offical_length}}</li>
   <li> Link Official Billing Start Date : {{link_data.link_official_billing_start_d}}</li>
   <li> Link Vendor Billing Start Date : {{link_data.link_vendor_billing_start_d}}</li>
   <li> Link Comment : {{link_data.link_comment}}</li>
   </ul>
</mat-card>
</div>