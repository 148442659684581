<mat-card class="static-page">

<h1 class="heading"> Our Company Profile</h1>

<p class="para">Our Company was incorporated as "Devesh Construction Pvt. Ltd." on 10th December 2003 under the Companies Act 1956 at Bihar and Jharkhand. Our status was subsequently changed as the name to "Actoss Technology Pvt. Ltd." on 19th May 2009.</p>
<p class="para">At the time of incorporation the registered office of our Company was situated at Dalmianagar, P.S. Dehri, Dist – Rohtas (Bihar) On 29th April 2009 the registered office was shifted to Opp. Dr. Colony PNB Building campas , Kankarbagh, Patna – 800020.</p>
<p class="para">Our Company is an ISO 9001:2008 certified organization which specializes in providing infrastructure services like Optical Fiber Cable ('OFC') implementation, Turnkey solutions (OFC and Copper) for connectivity, OFC blowing, OFC splicing, Horizontal Directional Drilling, etc. for various industries including Telecom, Cable Television, Internet Services, Power and Oil & Gas. We are registered as 'Infrastructure Provider Category - I' ('IP-I') with the Ministry of Communications & IT, Department of Telecommunications. Further, our Company offers ISP services in Gujarat for which we hold a license issued by the Ministry of Communications & IT, Department of Telecommunications.</p>
<p class="para">We started our business originally as a cable television operator in Patna having our own analogue network catering to home users. Since the year 2002 we started converting our analogue co-axial cable network to a hybrid fiber co-axial (HFC) network in which optical fiber cable and co-axial cable are used in different portions of a network to carry broadband content (such as video, data, and voice). This gave us an advantage as some of the characteristics of fiber optic cable (high bandwidth and low noise and interference susceptibility) could be brought close to our user without having to replace the then installed co-axial cable network. We started our OFC infrastructure services with small turnkey projects (OFC laying) for telecom companies in the year 2005. Our Company, in view of the opportunities in the OFC infrastructure business, decided to sell off the cable television business to concentrate its energies and focus on OFC infrastructure business. Having executed OFC infrastructure projects for our clients and considering the growing demand for high speed connectivity</p>

</mat-card>
