<mat-card class="static-page">

<h1 class="heading"> Our Partner</h1>

<div fxLayout="row" fxLayoutAlign="space-evenly center" f>   
    <mat-card class="card">
        <img  mat-card-image src="/assets/logo/railtel2.jpeg" style="width:350px; height:200px;">
        <mat-card-content>
             <p>Railtel</p>
        </mat-card-content>
    </mat-card>     

    <mat-card class="card">
        <img mat-card-image src="/assets/logo/bsnl.png" style="width:350px; height:200px;">
        <mat-card-content>      
            <p>BSNL</p>
        </mat-card-content>
    </mat-card>  

        <mat-card class="card">
        <img mat-card-image src="/assets/logo/powergrid1.jpeg" style="width:350px; height:200px;">
        <mat-card-content>
             <p>Power Grid</p>
        </mat-card-content>
    </mat-card>     
</div>

</mat-card>
