<mat-card class="static-page">

<h1 class="heading"> Our Services</h1>
<h2 class="sub-heading">Actoss Technology Pvt. Ltd. Offers the Following Services Route Survey & Design</h2>

<p class="para">The entire route is surveyed for laying of Optical Fiber Cables, for gathering the following inputs</p>
<ul>
    <li>Row Demarcation</li>
    <li>Soil Strata</li>
    <li>Existing Underground utilities</li>
    <li>Road / Rail / Bridge / River / Canal Crossing</li>
</ul>

<h2 class="heading-two">Survey and Digitized Maps</h2>

<p class="para">The survey digitized maps will have the following information</p>
<ul>
    <li>Relative position of proposed OFC route with respect of existing cables.</li>
    <li>Marking of Rocky, Submerged and Normal soil terrain.</li>
    <li>Marking of Crossing locations and type of proposed crossing detail viz.</li>
    <li>HDD, Open cut, and Boring etc.</li>
    <li>Geographical co-ordinates for all crossing HDPE duct coupling and OFC jointing locations.</li>
    <li>Assessment of required materials.</li>
    <li>Identifying Critical points.</li>
    <li>Demarcation of Row.</li>
</ul>

<h2 class="heading-two">Some Other Services</h2>
<ul>
    <li>Liasioning for RoW Permission</li>
    <li>Supply of Material</li>
    <li>Horizontal Directional Drilling</li>
    <li>Trenching, Ducting, Moling & Backfilling</li>
    <li>Duct Integrity Test</li>
    <li>OFC Pulling / Blowing</li>
    <li>OFC Drum Testing</li> 
</ul>

<p class="para">The Cable will be tested before pulling/blowing to ensure continuity also to evaluate the losses if any. Distribution Boxes The distribution boxes will be positioned at every location where a main cable will be distributed to sub - areas with lesser pair cables. Cable blowing Cable blowing machine (Cable jet or any other machine) would be deployed along with a good compressor. Cable drum will be loaded on payoff stand & unwound from topside of the drum. Pay off stand would be placed properly so that it does not collapsed while dispensing OFC.</p>



<h2 class="heading-two">OFC Fusion Splicing & Termination</h2>
<p class="para">Splicing is done using a good quality splicing machine. Splice loss per joint will be minimum and would not be more than 0.07 dB. In no case average splice loss per link will be more than 0.06 dB x No. of splices. At least 0.6 M to 0.8 M fiber would be stored in cable tray. Fiber would be neatly coiled without kinks. Minimum bending radius of 80mm would be ensured, Joint closure would be sealed properly before it is taken out of the controlled environment free from dust particles and air-conditioned splicing van.</p>


<h2 class="heading-two">OTDR Test</h2>
<p class="para">OTDR (Optical Time Domain Reflect meter) is used for measuring Fiber length, locate fiber fault, fiber losses. We provide splice Fiber trace report through the ODTR test. We generate bidirectional ODTR report if it is client requirement.</p>


<h2 class="heading-two">Laser Source / Power Meter Test (LSPM)</h2>
<p class="para">Link Test is carried out on entire section terminated at both ends after completion of tasks to ensure the losses are within permissible limits on all fibres.</p>


<h2 class="heading-two">In Building & Last Mile Connectivity</h2>
<p class="para">We are providing in building work solution for last mile BTS or Customer connectivity. We have expert team members for duct Clamping, Fiber laying & termination work for in building & last mile connectivity work. </p>


<h2 class="heading-two">Aerial / Overhead Cabling</h2>
<p class="para">At places where open trenching is not possible due to very narrow roads and due to delay in obtaining RoW, aerial cabling will be adopted as a temporary solution.</p>


<h2 class="heading-two">O&M of NLD / Backbone Route</h2>
<ul>
    <li>O&M team has a supervisor, splicer, assistant splicer, patroller and a helper for every range of defined by customer/Client.</li>
    <li>Patrolling is aimed at preventing cable disruptions along the OFC routes daily on fixed stretch.</li>
    <li>Preventive & Corrective Maintenance is carried out a defined by the SLA/SOW.</li>
    <li>All the Fibers are tested for the block sections and reported every month.</li>
    <li>Any defects in the fiber or in any of the associated equipment is attached to ,in consultation with the customer at site.</li>
    <li>Repeater stations and the optical equipment installed for alarms are monitored.</li>
    <li>Breakdown OFC Maintenance includes 24*7 mobilizations of equipment and skilled manpower for fault identification and rectification.</li>
    <li>Test link attributes like the splice loss/link loss after the rectification of the fault are corrected.</li>
    <li>Deployment of O&M teams to carry out Splicing and Termination of Optical Fiber Cable.</li>
    <li>Maintenance of OFC routes 24*7 for Routine OFC Maintenance and Breakdown OFC Maintenance.</li>
</ul>

<h2 class="heading-two">Optical Fiber</h2>

<p class="para">"Dark fiber" is optical fiber infrastructure that is in place but not presently being utilized by its owner. In the simplest terms, it is another name for optical glass fibers that have been
installed underground, but do not have any electronics or lasers attached. As these installations are unused, they are described as "Dark Fiber".</p>

<p class="para">Actoss has provisioned ample capacity of Dark Fiber available in network for in & around Bihar, Jharkhand and Part of Bengal.</p>

<p class="para">Actoss has been accredited with IP-1 license and is, the only service provider in its domain that provides customers with end-to-end connectivity services which includes network integration as well as managed services. This unique combination of services is what distinguishes Actoss from its competitors.
We provide End to End unlit Optical Fiber Connectivity in and around Surat city on lease to TELCO(s), ISP(s), CATV Operators, SME, Corporate Sector, Govt. and Semi Govt. Organization to operate their multiple branch operation.</p>
<h3>Dark Fiber can be used in below mention services</h3>
<ul>
    <li>Point to Point & Multi Point Connectivity.</li>
    <li>Last Mile Connectivity.</li>
    <li>Video Conferencing.</li>
</ul>


<h2 class="heading-two">We alos offer a range of services</h2>
<p class="para">Our Company offers services to corporates from various industries including telecom, cable television, internet services, power and oil & gas for their connectivity requirements. Our services range from providing connectivity for multiple office/branch operations to providing OFC solutions including .</p>
<ul>
    <li>Implementation of OFC Turnkey Project and its maintenance</li>
    <li>Route Survey and Liasioning for ROW (right of way) permission for cabling projects.</li>
    <li>Design & Planning for OFC Installation / OSP (Outside Premise) Network design and implementation; Horizontal Directional Drilling activities Trenching, Ducting, cable Laying, backfilling, etc.</li>
    <li>Duct Integrity Test, Cable Testing and other cable laying services.</li>
    <li>OFC Pulling /Jetting/ Blowing, Splicing, Testing & maintenance.</li>
    <li>In-building and last mile connectivity.</li>
    <li>Installation and maintenance of CATV networks.</li>
    <li>Installation and maintenance of LAN, WAN & MAN networks; and</li>
    <li>Internet services</li>
    <li>Lease of OFC Network</li>
</ul>

<h2 class="heading-two">We also offer customized services.</h2>
<p class="para">We offer customized services to meet the customers' needs and requirements, not deviating from the quality and range of service offerings for our customers. Modifying our services on various technical parameters and continuous process improvements helps us to deliver quality services at competitive prices to our customers.

<h2 class="heading-two">Our OFC Network</h2>
<p class="para">We have deployed dedicated Operations & Maintenance (O&M) Teams for 24*7 for monitoring of Underground OFC Network to avoid any physical damages & ensure high uptime of customers link signal. O&M Teams consist of Route Patrollers, Router Supervisor & Router wise Team Leader equipped with all network require tools, tackles & manpower. We have our In-House Fault Restoration Teams (FRT) & equipments like Splicing Machines, OTDRs, Fault Locators, LSMRs, dedicated FRT vehicles & HDD Machines to rectify any underground Network & fulfill the SLA (Service Level Agreement) made with our clients.</p>

<h2 class="heading-two">ISP Network Through Our Subsidiary</h2>
<p class="para">The industry requires powerful technology to sufficiently support the growth in data business and internet services; hence we utilize our fiber network to provide Internet services in and around Bihar & Jharkhand</p>
<p class="para">We have created more than 100 PoP's with MPLS Technology Based Managed Ring Network. We provide Internet services to corporate, SME & Broadband users on Fiber Optic Cable. We have also installed Wi-Max Technology based Wireless devices where our fiber reach is not available. We believe to provide maximum uptime & uninterrupted services to our valuable customers.</p>

<ul>
<li>More than 3000 building Coverage in and around Patna</li>
<li>More than 25 Wi-Max BS installed for Point to Multipoint Connectivity.</li>
</ul>

</mat-card>


