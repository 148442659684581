
export class VendorModel {
    uid :  string;
    vendor_id : string;
    vendor_name : string;
    vendor_city : string;
    vendor_state : string;
    vendor_pincode : string;
    vendor_country : string;
    vendor_address : String
}