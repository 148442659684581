<!--- Home banner start here -->
<mat-card class="banner-container">
    <div class="banner-home"> 
    <h1 class="banner-home-h1">Welcome to Actoss Technology</h1>
    <h3 class="banner-home-h3">One step solution for digital network starting from fiber conectivity to maintanance</h3>
    </div>
</mat-card>
<!--- Home banner Ends here -->


<div class="home-card-container">
    <div class="home-row" fxLayout="row" fxLayoutAlign="center center">
        <mat-card class="banner-card1">
            <i class="material-icons" style="font-size: 50px">settings_input_component</i>
            <h1 class="banner-card-header">Network Connection</h1>
            <h3 class="banner-card-para">End to End Network conectivity and support </h3>
        </mat-card>

        <mat-card class="banner-card2">
            <span class="material-icons" style="font-size: 50px">dns</span>
            <h1 class="banner-card-header">Server Setup </h1>
            <h3 class="banner-card-para">Any kind of server setup installation and Maintainance</h3>
        </mat-card>

        <mat-card class="banner-card3">
            <span class="material-icons" style="font-size: 50px">settings_input_hdmi</span>
            <h1 class="banner-card-header">Fiber To Home</h1>
            <h3 class="banner-card-para">Install, Maintaine and support fiber to home</h3>
        </mat-card>

        <mat-card class="banner-card4">
            <span class="material-icons" style="font-size: 50px">perm_data_setting</span>
            <h1 class="banner-card-header">Network Maintanance</h1>
            <h3 class="banner-card-para">We do All type of network Maintainance </h3>
        </mat-card>

    </div>
</div> <!-- core feature container -->




<div class="home-container">
    <div class="home-row" fxLayout="row" fxLayoutAlign="center stretch">
        <mat-card class="showcase-container">
        </mat-card>
        <mat-card class="about-card">

            <h1 class=""> What We Do</h1>
            <h2> Networking Solution </h2>
            <h5>We provide end to end networking solution from Equipment  to setup and mentainance . click to get more detail about our services.</h5>
            <button routerLink="services" mat-flat-button color="accent">Our Services <mat-icon>arrow_right_alt</mat-icon></button>

        </mat-card>
    </div>
</div>


<div class="home-contact-container">
    <div class="home-row" fxLayout="row" fxLayoutAlign="space-evenly start">
        <div class="contact-card">
            <div class="contact-detail">
            <h1 class="contact-h1"> Contact Us</h1>
            <div class="contact">
            <p class="contact-header">Actoss Technology Pvt Lmt.</p>
            <div class="ht-3px"></div>
            <p>PNB Building, Doctors Colony</p>
            <p>Kankarbagh, Patna</p>
            <p>Bihar, India</p>
            <div class="ht-3px"></div>
            <p>Phone Number : 0612-2352220 </p>
            <p>Email : info@actoss.co.in</p>
            <p>Website : www.actoss.co.in</p>
            </div>
            </div>
        </div>
        <div class="contact-card">
           <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3598.1797921651673!2d85.1554226148356!3d25.598941221566154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ed588e3cc5c4a3%3A0xef952b7d98e4d37c!2sActoss%20Technology%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1600677728483!5m2!1sen!2sin" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
        </div>
    </div>
</div>


<div class="client-card-container">
    <div>
        <h1 class="home-card-heading"> Our Client </h1>
    </div>
    <div class="home-row" fxLayout="row" fxLayoutAlign="center center">
        <div class="partner-1">
        <img class="center" src="/assets/logo/railtel.png" style="width:90px; height:90px;">
        </div>
        <div class="partner-1">
            <img class="center" src="/assets/logo/bsnl.png" style="width:130px; height:90px;">
        </div>

    </div>
</div> 