
<div class="auth-backgroud">
  <div class="auth-container">
    
    <div class="auth-center-container">
    <mat-toolbar>
      <mat-toolbar-row>
         <span>Sign In</span>
      </mat-toolbar-row>
    </mat-toolbar>

    <mat-card class="auth-card">

        <form class="signin-form"  [formGroup]="signInForm" (ngSubmit)="onSubmit()">
            <mat-form-field class="example-full-width">
              <mat-label>Email Address</mat-label>
              <input matInput placeholder="Email Address" value=" Email Address" formControlName="username" required>
              <mat-hint>Email Address</mat-hint>
              <mat-error *ngIf="username.errors?.required" >Email is required</mat-error>
              <mat-error *ngIf="username.errors?.email" > not a valid email</mat-error>  
            </mat-form-field>


            <mat-form-field class="example-full-width">
              <mat-label>Password</mat-label>
              <input matInput placeholder="Password" type="password" value="Password" formControlName="password" required>
              <mat-hint> Password </mat-hint>
              <mat-error>Please enter a password</mat-error>
            </mat-form-field>
        
            <button class="signin-button" mat-raised-button color="accent" style="width: 100%; display: block;" [disabled]="signInForm.invalid" >Submit</button>        
        </form>



    <mat-error></mat-error>
    <mat-divider></mat-divider>
    
    <div style="padding-top: 15px;"></div>
    <button class="signin-button" routerLink="/signup" mat-raised-button color="warn" style="width: 100%; display: block;">Don't have account - Sign Up</button> 
    <button class="signin-button" routerLink="/forgot-password" mat-raised-button color="warn" style="width: 100%; display: block;">Forget Password</button> 
    </mat-card>
    </div>
  </div>
</div>


