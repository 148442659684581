<mat-card class="static-page">

<h1 class="heading"> Office Address</h1>


<div class="home-contact-container">
    <div class="home-row" fxLayout="row" fxLayoutAlign="space-between start">
        
        <div class="contact-card">
            <div class="contact-detail">
                <div class="contact">
                    <p class="contact-header">Actoss Technology Pvt Lmt.</p>
                    <div class="ht-3px"></div>
                    <p>PNB Building, Doctors Colony</p>
                    <p>Kankarbagh, Patna</p>
                    <p>Bihar, India</p>
                    <div class="ht-3px"></div>
                    <p>Phone Number : 0612-2352220 </p>
                    <p>Email : info@actoss.co.in</p>
                    <p>Website : www.actoss.co.in</p>
                </div>
            </div>
        </div>
       
        <div class="contact-card">
           <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3598.1797921651673!2d85.1554226148356!3d25.598941221566154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ed588e3cc5c4a3%3A0xef952b7d98e4d37c!2sActoss%20Technology%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1600677728483!5m2!1sen!2sin" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
        </div>
    </div>
</div>
</mat-card>


