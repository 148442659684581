<mat-toolbar>
  <mat-toolbar-row class="header-row">
  <mat-icon>line_style</mat-icon> Project Dashboard 
    <div fxFlex fxLayout="row" fxLayoutAlign="flex-end">
      <ul fxLayout="row" fxLayoutGap="20px">
        <div class="header-button">
          <button *ngIf="role=='admin'" mat-stroked-button  routerLinkActive="list-item-active" (click)=" openInsertprojectDialog()">
          <mat-icon>add</mat-icon> Project
        
          </button>
        </div>
      </ul>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

<!-- Dashboard card 

      <div class="db-container">
          <div class="home-row" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
              <mat-card class="card-1">
                  <div fxLayout="row" fxLayoutAlign="space-between start">
                  <h3 class="card1-header">Total Project</h3>
                  <i class="material-icons" style="font-size: 20px">settings_input_component</i>                 
                  </div>
                   <h3 class="card1-header">100</h3>
              </mat-card>

              <mat-card class="card-1">
                  <div fxLayout="row" fxLayoutAlign="space-between start">
                  <h3 class="card1-header">Project in Piplene</h3>
                  <i class="material-icons" style="font-size: 20px">settings_input_component</i>                 
                  </div>
                   <h3 class="card1-header">100</h3>

              </mat-card>

              <mat-card class="card-1">
                  <div fxLayout="row" fxLayoutAlign="space-between start">
                  <h3 class="card1-header">Active Project</h3>
                  <i class="material-icons" style="font-size: 20px">settings_input_component</i>                 
                  </div>
                   <h3 class="card1-header">100</h3>
              </mat-card>
          </div>
      </div>

-->
<!--- Project Table -->

<div class="db-container">
<mat-card class="db-table-card">
<mat-toolbar>
  <span>Project Detail</span>
</mat-toolbar>


<div class="table-search" style="width:50% ; margin:2px 10px;">
  <mat-form-field appearance="outline">
    <mat-label>Project Detail Search</mat-label>
    <input matInput placeholder="" (keyup)="applyFilter($event.target.value)" >
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>
</div>
<div class="mat-elevation-z8">
  
  
  <table mat-table [dataSource]="dataSource" multiTemplateDataRows  >

    <!-- ID Column -->
    <ng-container matColumnDef="project_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header > ID </th>
      <td mat-cell *matCellDef="let row"> 
      <a routerLink="/project/{{row.project_uid}}">{{row.project_id}} </a>
      </td>
    </ng-container>


    <ng-container matColumnDef="project_estimated_cost">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Estimated Cost </th>
      <td mat-cell *matCellDef="let row"> {{row.project_estimated_cost}} </td>
    </ng-container>


   <!-- Progress Column -->
    <ng-container matColumnDef="project_manager">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Manager </th>
      <td mat-cell *matCellDef="let row"> {{row.project_manager}}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="project_status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
      <td mat-cell *matCellDef="let row"> {{row.project_status}} </td>
    </ng-container>


    <ng-container *ngIf="role=='admin'"  matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>  &nbsp; &nbsp; &nbsp; Actions </th>
      <td mat-cell *matCellDef="let row"> 
      <div  fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="2px">
        <button mat-icon-button color="warn" (click)="RemoveRecord(row.project_uid)"><mat-icon style="font-size: 20px">delete_outline</mat-icon></button>
        <button mat-icon-button color="primary" (click)="openEditprojectDialog(row)">
        <mat-icon style="font-size: 20px">create</mat-icon></button>
      </div>
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
    </tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>
</mat-card>
</div>



    <div *ngFor="let item of project_data">
            

    </div>


    