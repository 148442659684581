<div class="card">
<mat-card>

<ul>
   <li> Vendor Id : {{vendor_data.vendor_id}}</li>
   <li> Vendor Name : {{vendor_data.vendor_name}}</li>
   <li> Vendor City : {{vendor_data.vendor_city}}</li>
   <li> Vendor State  : {{vendor_data.vendor_state}}</li>
   <li> Vendor Pin Code : {{vendor_data.vendor_pincode}}</li>
   <li> Vendor Country : {{vendor_data.vendor_country}}</li>
   <li> Vendor Address {{vendor_data.vendor_address}}</li>

   </ul>
</mat-card>
</div>